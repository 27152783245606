.foot{
    background-image: url('../../resource/backfooter.jpg');
    min-height: 40vh;
    padding: 3vh 0;
    color: black;
}
.link{
    margin: 2vh auto;
    padding: 1vh 1vw;
}

.heads{
    color: #f35434;
}

a:active,a:visited,a:link{
    color: black;
    text-decoration: none;
}
